<template>
    <div class="section_wpr mb-2">
        <div class="edit_section">
            <sending-method v-model="notif_method" />
        </div>
    </div>
    <div v-if="notif_method === 1 || notif_method === 3">
        <label :for="`email-${uuid}`" class="switch_option capsule_btn m-0">
            <h5>Email</h5>
            <input type="checkbox" :id="`email-${uuid}`" v-model="emailAlert" hidden>
            <div><span></span></div>
        </label>
        <div class="setting_wpr mb-4" v-if="emailAlert">
            <email-component v-if="loadEditor" v-model="emailContent" :errors="errors" :handle-default-email="handleDefaultEmail" ref="email-component" />
        </div>
    </div>
    <div v-if="notif_method === 2 || notif_method === 3">
        <label :for="`sms-${uuid}`" class="switch_option capsule_btn">
            <h5>SMS Setup</h5>
            <input type="checkbox" :id="`sms-${uuid}`" v-model="smsAlert" hidden>
            <div><span></span></div>
        </label>
        <div class="setting_wpr" v-if="smsAlert">
            <sms-component v-if="loadEditor" v-model="delivery_sms" :media="media" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" ref="sms-component" />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { uuid } from 'vue-uuid'

    export default {
        name: 'Birthday Notification Setting',

        data() {
            return {
                uuid: 'uuid-'+uuid.v4(),
                emailAlert: false,
                smsAlert: true,
                notif_method: 1,
                delivery_sms: '',
                media: [],
                emailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                loadEditor: false,
            }
        },

        props: {
            modelValue: Object,
            errors: Object,
            type: String
        },

        watch: {
            'modelValue.media' (media) {
                const vm = this;

                vm.media = media;
            },
        },

        mounted () {
            const vm = this;

            vm.resetForm();

            vm.loadEditor = false;

            setTimeout(function () {
                vm.loadEditor = true;
            }, 1000);
        },

        methods: {
            resetForm () {
                const vm = this;

                if (vm.type == 'greeting') {
                    var message   = 'Hi  reminder {{first_name}}\n';
                    message      += 'I wanted to wish you a very Happy Birthday from myself and all of us here at {{company_name}}! I hope your special day is AMAZING!\n';
                    message      += '{{owner_first_name}}\n';

                    var content = `<p>Hi {{first_name}}</p>
                                   <p>I wanted to wish you a very Happy Birthday from myself and all of us here at {{company_name}}!</p>
                                   <p>I hope your special day is AMAZING!</p>
                                   <p>{{owner_first_name}}</p>
                                   <p></p>`;
                } else {
                    var message   = 'Hi {{owner_first_name}}\n';
                    message      += '{{full_name}} has a birthday coming up on {{DOB}}.\n';
                    message      += 'This is your {{reminder_settings}} reminder.\n';
                    message      += 'Cheers,';
                    message      += 'OM';

                    var content = `<p>Hi {{owner_first_name}}</p>
                                  <p>{{full_name}} has a birthday coming up on {{DOB}}.</p>
                                  <p>This is your {{reminder_settings}} reminder.</p>
                                  <p>Maybe you could do something nice for them to show your appreciation.</p>
                                  <p>Cheers,</p>
                                  <p>OM</p>`;
                }

                var subject = vm.type == 'greeting' ? 'Your special day {{first_name}}!' : '{{full_name}} has an upcoming birthday!';

                vm.notif_method = vm.modelValue.sending_method;
                vm.media        = vm.modelValue.media;
                vm.delivery_sms = vm.modelValue.sms_message ? vm.modelValue.sms_message : message;
                vm.emailContent = {
                    defaultHandler: true,
                    subject: vm.modelValue.email_subject ? vm.modelValue.email_subject : subject,
                    email: vm.modelValue.email_message ? vm.modelValue.email_message : content,
                    show_email_logo: vm.modelValue.show_email_logo,
                    email_logo: vm.modelValue.logo_image ? vm.modelValue.logo_image : '',
                    is_email_logo: vm.modelValue.has_logo ? vm.modelValue.has_logo : 0,
                    is_signature: vm.modelValue.show_email_signature ,
                    signature_id: vm.modelValue.signature_id,
                };
                vm.emailAlert = true;
            },

            handleDefaultEmail () {
                const vm = this;
                if (vm.type == 'greeting') {
                    vm.emailContent  = {
                        defaultEmail: true,
                        subject: 'Your special day {{first_name}}!',
                        email: `<p>Hi {{first_name}}</p>
                               <p>I wanted to wish you a very Happy Birthday from myself and all of us here at {{company_name}}!</p>
                               <p>I hope your special day is AMAZING!</p>
                               <p>{{owner_first_name}}</p>
                               <p></p>`,
                    }
                } else {
                    vm.emailContent  = {
                        defaultEmail: true,
                        subject: '{{full_name}} has an upcoming birthday!',
                        email: `<p>Hi {{owner_first_name}}</p>
                                <p>{{full_name}} has a birthday coming up on {{DOB}}.</p>
                                <p>This is your {{reminder_settings}} reminder.</p>
                                <p>Maybe you could do something nice for them to show your appreciation.</p>
                                <p>Cheers,</p>
                                <p>OM</p>`,
                    }
                }
            },

            handleDefaultSms () {
                const vm = this;

                if (vm.type == 'greeting') {
                    vm.delivery_sms  = 'Hi  reminder {{first_name}}\n';
                    vm.delivery_sms += 'I wanted to wish you a very Happy Birthday from myself and all of us here at {{company_name}}! I hope your special day is AMAZING!\n';
                    vm.delivery_sms += '{{owner_first_name}}\n'
                } else {
                    vm.delivery_sms  = 'Hi {{owner_first_name}}\n';
                    vm.delivery_sms += '{{full_name}} has a birthday coming up on {{DOB}}.\n';
                    vm.delivery_sms += 'This is your {{reminder_settings}} reminder.\n'
                    vm.delivery_sms += 'Cheers,'
                    vm.delivery_sms += 'OM'
                }
            }
        }
    }
</script>
